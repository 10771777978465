import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import CTA_home from '../Home/CTA_home';
import Industries_carousel_pa from './Industries_carousel_pa';
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from './FAQ_model_one';
import Main_tabs_demo from "../modules/tabsdemo/Main_tabs_demo";
import FAQ_business_types from './FAQ_business_types';


const Horeca = () => {
    const [height, setHeight] = useState('1000px');
  const isMobile = window.innerWidth <= 768; 

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 640) {
        setHeight('350px'); // Móviles pequeños
      } else if (width <= 768) {
        setHeight('600px'); // Tabletas en orientación vertical
      } else if (width <= 1024) {
        setHeight('650px'); // Tabletas en orientación horizontal y laptops pequeñas
      } else if (width <= 1280) {
        setHeight('800px'); // Laptops medianas y monitores pequeños
      } else {
        setHeight('885px'); // Monitores grandes y resoluciones mayores
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener('resize', handleResize);
  }, []);

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      <h2 class="mt-20 max-w-5xl px-4 bg-white mb-4 font-sans text-3xl text-center font-semibold leading-none tracking-tight text-gray-900 sm:text-[60px] md:mx-auto">
        {t("demoprototypetitlehoreca")} <span className='bg-gradient-clip11'>Invu POS</span>  {t("demoprototypetitlehoreca1")}
      </h2>
      <p className='text-gray-600 text-center mt-3 text-lg max-w-2xl mx-auto'>{t("demoprototypedescription")}</p>

      <iframe
        // src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fm2SxDJKgzkr9K5UCoTn4Ya%2FPrototype-system-Invu%3Fnode-id%3D533-309%26node-type%3DCANVAS%26t%3DtOnevvtQivOeGHGX-1%26content-scaling%3Dfixed%26page-id%3D533%253A308&hide-ui=1"
        src="https://embed.figma.com/proto/m2SxDJKgzkr9K5UCoTn4Ya/Prototype-system-Invu?node-id=2742-214&p=f&26content-scaling%3Dfixed&26page-id=2742%3A213&embed-host=share&hide-ui=1"
        allowFullScreen
        style={{
          width: '100%',
          height: height,
          // margin: isMobile ? '0px' : '0', 
          // padding: isMobile ? '12px' : '0', 
          border: 'none',
        }}
      />


    <div className="mt-8 px-4 py-0 mx-auto text-left sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div className="max-w-xl mb-4 sm:text-left lg:max-w-5xl md:mb-12">
          <h2 className="max-w-5xl mb-6 font-sans text-3xl font-semibold leading-none tracking-tight text-gray-900 sm:text-[40px] md:mx-auto">
          {t("demotabstitle")}
          </h2>
          <p className='text-gray-500 text-xl'>
         {t("demotabsdescription")}
          </p>
        </div>
      </div>
      <Main_tabs_demo/>

      <section>
        <div class="max-w-screen-xl px-4 mt-12 mx-auto sm:px-6 lg:px-8">
       <div className="flex bg-gradient-to-r from-[#eff1fe] to-[#cad0fb] flex-col justify-between px-4 mx-auto lg:flex-row md:px-12 lg:max-w-scr sm:px-6 lg:px-12een-xl rounded-2xl">
      <div className="pt-16 lg:mb-16 mb-10 lg:mb-0 lg:pt-20 lg:max-w-lg lg:pr-5">
        <div className="max-w-xl mb-6">
          <h2 className="max-w-lg mb-6 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
          {t("posctacontacttitle")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
          {t("posctacontactposdescription")}
          </p>
        </div>
        <div className="flex items-center">
        <button
        onClick={() => {
        //   setIsOpen(true);
        }}
            class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-indigo-600 bg-indigo-600 px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
          >
            <span
              class="font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
            >
              {t("posctacontactbutton")}
            </span>

            <span
              class="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500"
            >
              <svg
                class="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div className="items-center flex content-center lg:mb-0 mb-12">
        <img
          src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/mockupterminalnew.png"
          className="w-[450px] xl:mr-20 rounded-3xl"
          alt=""
        />
      </div>
    </div>
    </div>
    </section>

     <Industries_carousel_pa/>

     <section>
        <div class="max-w-screen-xl px-4 mt-24 mb-12 mx-auto sm:px-6 lg:px-8">
       <div className="flex bg-gradient-to-r from-[#eff1fe] to-[#cad0fb] flex-col justify-between px-4 mx-auto lg:flex-row md:px-12 lg:max-w-scr sm:px-6 lg:px-12een-xl rounded-2xl">
      <div className="pt-16 mb-16 lg:mb-0 lg:pt-16 lg:max-w-2xl ">
        <div className="max-w-2xl mb-4">
          <h2 className="max-w-2xl mb-4 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
         {t("democtatitle")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
        {t("democtadescription")} {" "}
          <span className='font-semibold'>{t("democtadescriptionspan")}</span>
          </p>
        </div>
        <div className="flex items-center">
        <button
        onClick={() => {
        //   setIsOpen(true);
        }}
            class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-indigo-600 bg-indigo-600 px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
          >
            <span
              class="font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
            >
              {t("democtabutton")}
            </span>

            <span
              class="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500"
            >
              <svg
                class="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div>
        <img
          src="https://img.invupos.com/webinvu/CTA/AVATAR-CTA1.png"
          className="h-[415px] mx-auto xl:mr-32 md:max-w-sm lg:-mt-0 -mt-12"
          alt=""
        />
      </div>
    </div>
    </div>
    </section>

    </>
  )
}

export default Horeca